.paymentScreen__plan {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  opacity: 0.8;
}

.paymentScreen__plan:hover {
  opacity: 1;
}

.paymentScreen__plan > button {
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: #0202fc;
  font-weight: 600;
  border: none;
  cursor: pointer;
}

.paymentScreen__plan--disabled > button {
  background-color: gray !important;
}
